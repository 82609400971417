<template>
  <!-- order items modal -->
  <b-modal
    id="delete-item-row-modal"
    ref="deleteProductModal"
    title="Delete an Item"
    size="lg"
    header-class="font-primary"
    header-bg-variant="danger"
    header-text-variant="white"
    header-border-variant="white"
    footer-bg-variant="light"
    footer-border-variant="white"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <b-button
          variant="light"
          class="my-2 my-sm-0 mr-0 mr-sm-2 bg-gray-550 text-white action-btn"
          @click="cancel"
          pill
        >
          Cancel
        </b-button>
        <b-button
          variant="danger"
          class="my-2 my-sm-0 ml-0 ml-sm-2 action-btn"
          pill
          @click="deleteFn"
        >
          Delete
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-white"
        @click="closeModal()"
        aria-label="Close Modal"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <p class="text-left text-dark mb-4">
        Are you sure you want to delete this Item Code?
      </p>
    </b-row>
  </b-modal>
</template>

<script>
// services
import { DeleteItem } from "@/services/items.service";

export default {
  name: "DeleteProductModal",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    // itemIndex: {
    //   default: null,
    //   type: Number,
    // },
    itemContent: {
      default: null,
    },
  },
  methods: {
    async deleteFn() {
      console.log("item", this.itemContent);
      if (!this.itemContent.id) return false;
      let payload = {
        id: this.itemContent.id,
      };
      try {
        let { data, status } = await DeleteItem(payload);
        if (status == 200) {
          console.log(data);
          let payloadNotify = {
            isToast: true,
            title: "Delete Item Codes",
            content: "The Item Codes has been removed successfully.",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
        }
      } catch (error) {
        console.log(error);
      }
      // .then(async (res) => {
      //   console.log(res.data);
      //   if (res.data.message == "success") {
      //     let payloadNotify = {
      //       isToast: true,
      //       title: "Delete Administrator",
      //       content:
      //         "The question (" +
      //         this.itemContent.name +
      //         ") has been removed successfully.",
      //       variant: "success",
      //     };

      //     this.$store.dispatch("notification/setNotify", payloadNotify);
      //     this.$emit("reloadFn");
      //   }
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
      // console.log(this.itemContent);
    },
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>
<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
