import axios from "axios";

export const AddItems = async (payload) => {
  try {
    return await axios.post(`/items/create`, payload);
  } catch (error) {
    return error;
  }
};

export const GetAllItems = async (params) => {
  try {
    return await axios.get("/items", { params });
  } catch (error) {
    return error;
  }
};

export const GetSingleItem = async (payload) => {
  try {
    return await axios.get(`/items?id=${payload}`);
  } catch (error) {
    return error;
  }
};

export const UpdateItem = async (payload) => {
  try {
    return await axios.put(`/items/update/${payload.id}`, payload);
  } catch (error) {
    return error;
  }
};

export const DeleteItem = async (payload) => {
  try {
    return await axios.delete(`/items/delete/${payload.id}`);
  } catch (error) {
    return error;
  }
};

export const SearchItem = ({ text,full_name }) => {
  return axios.get(`/items/search`, {
    params: { text,full_name },
  });
};

export const GetGlCode = async () => {
  try {
    return await axios.get(`/chart_of_account/gl_codes`);
  } catch (error) {
    return error;
  }
};
