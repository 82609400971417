<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 table-container-card w-100">
          <!-- table actions row -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-lg-3 py-3"
            no-gutters
          >
            <!-- search input column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-4 px-lg-0 py-2 py-lg-0"
              cols="12"
              md="4"
            >
              <!-- table search input -->
              <b-input-group class="search-input-group">
                <b-form-input
                  class="search-input border-0 bg-light text-main-green"
                  type="text"
                  placeholder="Search by item code"
                  v-model="searchTable"
                  @keyup.native.enter="searchFn"
                ></b-form-input>
                <b-input-group-append>
                  <b-row
                    class="d-flex flex-row align-items-center justify-content-center px-3 border-0 rounded-right bg-light"
                    no-gutters
                  >
                    <template v-if="searchTable">
                      <b-icon
                        class="text-main-green mr-2"
                        icon="x"
                        size="lg"
                        aria-label="Search"
                        @click="close"
                      ></b-icon>
                    </template>
                    <b-icon
                      class="text-main-green"
                      icon="search"
                      size="lg"
                      aria-label="Search"
                    ></b-icon>
                  </b-row>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-end justify-content-center mr-2 py-2 py-lg-0"
              cols="12"
              md="4"
            >
              <b-button
                variant="main-green"
                class="d-flex flex-row align-items-center justify-content-center mx-4 mx-md-0 my-2 my-lg-0 px-3 py-2 border-0 rounded-lg add-question-btn"
                to="/products/create"
                pill
                :disabled="getUserRole.role.includes('User')"
              >
                <span class="d-inline ml-2"
                  >Add a Item Code
                  <!--<span class="d-none d-lg-inline">or Service</span>--></span
                >
                <!-- <span class="d-inline ml-2">Add a Product or Service</span> -->
              </b-button>
            </b-col>
          </b-row>
          <!-- table row -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
            no-gutters
          >
            <b-table
              ref="questionsTable"
              :bordered="false"
              :borderless="true"
              :hover="true"
              selectable
              show-empty
              select-mode="single"
              class="table-element"
              thead-class="table-header font-secondary"
              tbody-class="table-body"
              :busy="isLoading"
              :per-page="perPage"
              :fields="productsHeader"
              :items="productItems"
            >
              <!-- table busy state -->
              <template #table-busy>
                <div class="text-center text-muted my-3">
                  <b-spinner class="align-middle"></b-spinner>
                  <span class="text-left mx-1">Loading...</span>
                </div>
              </template>
              <!-- table empty state -->
              <template #empty="scope">
                <b-row
                  class="d-flex flex-row align-items-center justfiy-content-center py-5"
                  no-gutters
                >
                  <h6 class="text-center mb-0 mx-auto my-5">
                    {{ scope.emptyText }}
                  </h6>
                </b-row>
              </template>
              <!-- customer Name  -->
              <template #cell(productDetails)="data">
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0"
                >
                  <span class="main-text">{{ data.value.productName }}</span>
                  <span class="text-muted sub-text">{{
                    data.value.productDescription
                  }}</span>
                </b-col>
              </template>
              <!-- customer Email -->
              <template #cell(productPrice)="data">
                <span class="main-text mb-2"
                  >{{ data.value ? numberFormat(data.value) : 0 }}
                  {{ currency }}</span
                >
              </template>
              <!-- actions column -->
              <template #cell(actions)="data">
                <!-- edit account button -->
                <b-button
                  v-b-tooltip.hover.left
                  title="Edit Account"
                  class="shadow-none mx-1 border-0 edit-btn"
                  pill
                  @click="editProductFn(data.value.id)"
                  :disabled="getUserRole.role.includes('User')"
                >
                  <b-icon
                    icon="pencil-square"
                    aria-label="Edit Account"
                    class="edit-icon"
                  ></b-icon>
                </b-button>
                <!-- delete account button -->
                <b-button
                  v-b-tooltip.hover.left
                  title="Delete Account"
                  class="shadow-none mx-1 border-0 delete-btn"
                  pill
                  @click="openDeleteProductModal(data.value, $event.target)"
                  :disabled="getUserRole.role.includes('User')"
                >
                  <b-icon
                    icon="trash"
                    aria-label="Delete Account"
                    class="delete-icon"
                  ></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-row>
          <!-- table controls -->
          <!-- safari fix (flex-shrink-0) -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-shrink-0 px-3 py-2 w-100"
            no-gutters
          >
            <!-- pages per page control -->
            <b-col
              class="d-flex flex-column align-items-center align-items-sm-start align-items-md-end align-items-xl-start justify-content-center py-2 py-md-1"
              cols="12"
              sm="6"
              lg="5"
              xl="2"
            >
              <b-form-group
                label="Per page"
                label-cols="12"
                label-cols-sm="6"
                label-align="left"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0 w-100"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  class="per-page-select border-1 border-main-green py-0"
                  :options="pageOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- pagination controls -->
            <b-col
              class="d-flex flex-column align-items-center align-items-sm-end justify-content-center py-2 py-md-1"
              cols="12"
              sm="6"
              lg="4"
              xl="5"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                size="md"
                limit="4"
                pills
                class="table-pagination"
                page-class="custom-page-item"
                ellipsis-class="custom-page-item"
                first-class="custom-page-item"
                last-class="custom-page-item"
                next-class="custom-page-item"
                prev-class="custom-page-item"
              >
                <template #first-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-left"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
                <template #prev-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-double-left"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
                <template #next-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-double-right"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
                <template #last-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-right"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </b-card>
        <DeleteProductModal
          :isShow="isShowDeleteItemRow"
          @onClose="onCloseDeleteProductModal"
          :key="showDeleteProductModalKey"
          :itemContent="rowItem"
          @reloadFn="callInitFn"
        />
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import DeleteProductModal from "@/components/Products/modals/DeleteProductModal";
// services
import { GetAllItems, SearchItem } from "@/services/items.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import { formatNumbers } from "@/util/formatNumbers.js";
import {mapGetters} from "vuex";
export default {
  name: "Products",
  components: {
    HomeLayout,
    DeleteProductModal,
  },
  data() {
    return {
      isShowDeleteItemRow: false,
      showDeleteProductModalKey: Number(new Date()) + 100,
      rowItem: null,
      rowIndex: null,
      filterDropDownShow: false,
      selectedRegion: {
        title: null,
        code: null,
        flagCode: null,
        languageCode: null,
      },
      isRegionFilter: false,
      searchTable: null,
      isLoading: false,
      productsHeader: [
        {
          key: "productDetails",
          label: "Item Code",
          sortable: true,
        },
        {
          key: "productPrice",
          label: "Unit Price",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
      productItems: [
        // {
        //   customerName: {
        //     name: "Mike Rogers",
        //     business: "City Goods",
        //   },
        //   customerEmail: "mikerogers@gmail.com",
        //   customerPhone: "0713456782",
        //   customerAmount: {
        //     balance: "Rs. 4,000.00",
        //     overdue: "Rs. 2,000.00 overdue",
        //   },
        // },
        // {
        //   customerName: {
        //     name: "David Karp",
        //     business: "Brands Online",
        //   },
        //   customerEmail: "davidkarp@gmail.com",
        //   customerPhone: "0713454562",
        //   customerAmount: {
        //     balance: "Rs. 3,000.00",
        //     overdue: "Rs. 2,500.00 overdue",
        //   },
        // },
        // {
        //   customerName: {
        //     name: "Joshua Dziabiak",
        //     business: "Aurora Market",
        //   },
        //   customerEmail: "joshuadziabiak@gmail.com",
        //   customerPhone: "0713456782",
        //   customerAmount: {
        //     balance: "Rs. 6,000.00",
        //     overdue: "Rs. 5,000.00 overdue",
        //   },
        // },
        // {
        //   customerName: {
        //     name: "Aaron Levie",
        //     business: "Galaxy Store",
        //   },
        //   customerEmail: "aaronlevie@gmail.com",
        //   customerPhone: "0713456782",
        //   customerAmount: {
        //     balance: "Rs. 5,000.00",
        //     overdue: "Rs. 4,000.00 overdue",
        //   },
        // },
        // {
        //   customerName: {
        //     name: "Sean Whalen",
        //     business: "Giant E-Store",
        //   },
        //   customerEmail: "seanwhalen@gmail.com",
        //   customerPhone: "0713456782",
        //   customerAmount: {
        //     balance: "Rs. 7,000.00",
        //     overdue: "Rs. 5,500.00 overdue",
        //   },
        // },
      ],
      // table pagination controls
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      currency: localStorage.getItem("CURRENCY"),
    };
  },
  watch: {
    searchTable() {
      this.searchFn();
    },
    perPage() {
      this.initFn();
    },
    currentPage() {
      this.initFn();
    },
  },
  async mounted() {
    await this.loadCurrentWorkspace();
    await this.initFn();
  },
  methods: {
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },

    numberFormat(val) {
      return formatNumbers(val);
    },

    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetAllItems({
          limit: this.perPage,
          page: this.currentPage,
        });
        console.log(data);
        this.totalRows = data.results.total;
        this.productItems = data.results.results.map((x) => {
          return {
            productDetails: {
              productName: x.name,
              productDescription: x.description,
            },
            productPrice: x.price,
            actions: {
              id: x._id,
            },
            // customerAmount: {
            //   balance: "Rs. 4,000.00",
            //   overdue: "Rs. 2,000.00 overdue",
            // },
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async SearchItems() {
      try {
        this.productItems = [];
        this.isLoading = true;
        let { data } = await SearchItem({ text: this.searchTable });
        console.log(data);
        this.productItems = data.data.map((x) => {
          return {
            productDetails: {
              productName: x.name,
              productDescription: x.description,
            },
            productPrice: x.price,
            actions: {
              id: x._id,
            },
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    searchFn() {
      if (!this.searchTable || this.searchTable.length < 1) {
        this.initFn();
      } else {
        this.SearchItems();
      }
    },
    close() {
      this.searchTable = null;
      this.initFn();
    },
    openDeleteProductModal(item, button) {
      // this.$store.dispatch("setSelectedItem", item);
      // console.log("delete item", item);
      this.isShowDeleteItemRow = true;
      this.rowItem = item;
      this.$root.$emit("bv::show::modal", "delete-item-row-modal", button);
    },
    onCloseDeleteProductModal() {
      this.isShowDeleteItemRow = false;
    },
    callInitFn() {
      this.showDeleteProductModalKey = Number(new Date()) + 100;
      this.initFn();
    },
    editProductFn(id) {
      this.$router.push("/products/edit/" + id);
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole"
    }),
  },
};
</script>
<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 0;
  border: 1px solid var(--light);
}
/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  min-width: 200px;
}
.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}
.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .add-question-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}
.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  min-width: 1000px;
}
/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}
.table-element >>> .table-body td {
  vertical-align: middle;
}
.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}
/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}
/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}
/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: #0b9b7d;
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}
/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}
/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}
/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: #0b9b7d;
}
.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}
/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}
/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}
.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}
/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}
/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}
.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}
.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}
/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-color: transparent;
  border-radius: 0;
  color: var(--dark);
  padding: 0.5rem 0.6rem;
  font-size: 18px;
  height: 20px;
  transition: all 150ms ease-in;
}

.table-pagination >>> .custom-page-item button:hover {
  color: #0b9b7d;
  /* border-color: var(--secondary); */
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: #0b9b7d;
  border: none;
  border-color: transparent;

  font-weight: bold;
  background-color: transparent;
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  opacity: 0.5;
  border-color: transparent;
  background-color: transparent;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }
}
</style>
